<template>
  <div class="page_bg newpage" style="margin:0;">
    <div class="logo_img flex-r-s-s">
      <van-image width="4.27rem" height="1.97rem"
                 src="https://oss.xiaoyi120.com/shop2.0/login/login_logo3.png"
      />
    </div>
    <div class="login_form flex-c-s-c">
      <van-form @submit="onSubmit">
        <template>
          <div class="flex-r-s-c">
            <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon3.png" />
            <div class="login_form_msg">所在地区</div>
          </div>
          <van-field readonly clickable :value="loginForm.districtName" placeholder="请选择您的地址" right-icon="arrow"
                     @click="showPicker = true"
          />
        </template>
        <template>
          <div style="margin-top:0.6rem" class="flex-r-s-c">
            <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon4.png" />
            <div class="login_form_msg">机构名称</div>
          </div>

          <van-field v-model="loginForm.companyName" type="text" name="companyName" placeholder="请输入您的机构名称"
                     :rules="[{ required: true, message: '' }]"
          />
        </template>

        <div style="margin-top:0.6rem" class="flex-r-s-c">
          <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon1.png" />
          <div class="login_form_msg">手机号</div>
        </div>
        <van-field v-model="loginForm.mobile" type="tel" name="mobile" placeholder="请输入手机号" maxlength="11"
                   :rules="[{ required: true, message: '' }]"
        />
        <div style="margin-top:0.6rem" class="flex-r-s-c">
          <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon2.png" />
          <div class="login_form_msg">验证码</div>
        </div>
        <van-field v-model="loginForm.checkCode" type="digit" class="van-field" name="checkCode" placeholder="请输入验证码"
                   maxlength="6" :rules="[{ required: true, message: '' }]"
        >
          <template #extra>
            <van-count-down v-if="isSend" :time="time" format="ss 秒重新发送" @finish="finish" />
            <div v-else class="send_message" @click="sendMessage">获取验证码</div>
          </template>
        </van-field>
        <div class="agree_form">
          <van-checkbox v-model="checked" checked-color="#0767ab" icon-size="0.4rem">
            点击登录/注册即视为您已同意<span class="f-blue" @click="toAgreement">《用户注册协议》</span>
          </van-checkbox>
        </div>
        <div class="login_sub">
          <van-button round block :loading="loading" type="info" loading-text="注册中..." native-type="submit"
                      color="#0767ab" style="font-size: 0.43rem;"
          >立即注册
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="login_register flex-r-s-c" @click="toRegister">
      已有账号？立即登录
      <van-image width="0.53rem" height="0.53rem"
                 src="https://oss.xiaoyi120.com/shop2.0/login/login_arrow_right.png"
      />
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
  </div>
</template>

<script>
import './../index.scss'
// 接口
import { districts } from '@/api/public'
import { sms_send, mobile_register3 } from '@/api/user'
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type'],
  data() {
    return {
      isSend: false,
      loading: false,
      time: 60 * 1000,
      showPicker: false,
      cascadervalue: '',
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      loginForm: {
        mobile: '', checkCode: '', openId: '', platformType: 'wxmp' // 微信(H5):wxmp 微信:wx
      },

      isLogin: true,

      checked: false
    }
  },
  created() {
    if (localStorage.getItem('openId') || this.$store.getters.openid) {
      this.loginForm.openId = localStorage.getItem('openId') || this.$store.getters.openid
    }
    this.loadDistrict()
  },
  methods: {
    // 点击登录
    onSubmit(values) {
      if (this.checked) {
        this.loginForm.categoryId = 1
        this.loginForm.shopType = parseInt(this.type)
        this.loading = true
        mobile_register3(this.loginForm).then(res => {
          this.$toast({ message: '注册成功！', duration: 3 * 1000 })
          res.data.openid = this.openid
          res.data.ref = this.url
          this.$utils.storeUserInfo(this.$store, res.data)
          this.loading = false
          this.$utils.localLogin(this, res.data, 'login_pet')
          this.$nextTick(() => { sessionStorage.setItem('liveFlag', 1); sessionStorage.setItem('liveFlag_cart', 1) })
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      } else {
        this.$toast({
          message: '请勾选同意后进行操作'
        })
      }
    },
    // 发送短信
    sendMessage() {
      if (this.loginForm.mobile.length === 11) {
        const parms = { mobile: this.loginForm.mobile, type: 'login' }
        sms_send(parms).then(res => {
          this.$toast({ message: '已发送！', duration: 3 * 1000 })
          this.isSend = true
        })
      } else {
        this.$toast({ message: '请输入手机号', position: 'top', duration: 3 * 1000 })
      }
    },
    // 倒计时结束触发
    finish() { this.isSend = false },
    // 去注册
    toRegister() {
      this.$router.push('login')
    },
    // 省市区
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 去注册协议
    toAgreement() { this.$router.push('agreement') },
    // 去隐私政策
    toPrivacy() { this.$router.push('privacy') },

    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.loginForm.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.loginForm.provinceId = value.selectedOptions[0].value
      this.loginForm.cityId = value.selectedOptions[1].value
      this.loginForm.areaId = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() {
      this.showPicker = false
    }

  }
}
</script>

<style>
html {
  background: #fff;
}
.newpage {
  background: url("https://oss.xiaoyi120.com/shop2.0/login/login_bg.png");
  background-size: 10rem 10.13rem;
  background-repeat: no-repeat;
}
</style>
