<template>
  <div>
    <registerStep01 v-if="step === 1" ref="step01" @nextStep="nextStep" />
    <registerStep02 v-if="step === 2" ref="step02" :type="type" @nextStep="nextStep" />
  </div>
</template>

<script>
import './index.scss'
import registerStep01 from './components/register_step01'
import registerStep02 from './components/register_step02'
export default {
  name: 'Register',
  components: { registerStep01, registerStep02 },
  data() {
    return {
      step: 1,
      type: null
    }
  },
  methods: {
    // 进入第二步
    nextStep(e) {
      this.type = e < 3 ? e : null
      this.step = e < 3 ? 2 : 1
    }
  }
}
</script>
