<template>
  <div class="page_bg" style="padding:0.48rem;background: #ffffff;">
    <!-- <div class=" flex-r-s-c" @click="$utils.pathGoBack(-1)">
      <van-icon name="down" style="font-size: 0.57rem; transform:rotate(90deg) " />
    </div> -->
    <div class="choose_title">请选择您的店铺类型</div>
    <div class="choose_card flex-r-sa-c">
      <div class="choose_card_bg1 flex-c-c-c" @click="choosed(1)">
        <van-image width="3.04rem" height="3.04rem" src="https://oss.xiaoyi120.com/shop2.0/login/register_img01.png" />
        <div class="card_name">线上电商</div>
      </div>
      <div class="choose_card_bg2 flex-c-c-c" @click="choosed(2)">
        <van-image width="3.04rem" height="3.04rem" src="https://oss.xiaoyi120.com/shop2.0/login/register_img02.png" />
        <div class="card_name">线下门店</div>
      </div>
    </div>
    <!-- <div class="bottom_xieyi flex-r-c-c">
      点击登录即视为您已同意<span class="f-blue" @click="toAgreement">《用户注册协议》</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'RegisterStep01',
  data() {
    return {

    }
  },
  methods: {
    // 选择行业 0：宠物医院 1：供应商
    choosed(type) { this.$emit('nextStep', type) },
    // 去注册协议
    toAgreement() { this.$router.push('/user/agreement') }
  }
}
</script>

<style>
.choose_card_bg1 {
  width: 4.45rem;
  height: 6.99rem;
  background: url("https://oss.xiaoyi120.com/shop2.0/login/register_bg01.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.choose_card_bg2 {
  width: 4.45rem;
  height: 6.99rem;
  background: url("https://oss.xiaoyi120.com/shop2.0/login/register_bg02.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.card_name {
  font-weight: 700;
  font-size: 0.48rem;
  line-height: 0.96rem;
  color: #333;
}
</style>
